<template>
  <g>
    <svg:style>
      .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, .cls-9 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-4 {
        fill: url(#inox-gradient);
      }

      .cls-5 {
        fill: url(#inox-gradient-2);
      }

      .cls-6 {
        fill: url(#inox-gradient-3);
      }

      .cls-7,
      .cls-8,
      .cls-9 {
        fill: url(#glass-pattern);
      }
      .Solid { fill: {{ doorSolidColor }} }
      .Molding-L { fill: url(#Molding-L); } /* left */
      .Molding-T { fill: url(#Molding-T); } /* top */
      .Molding-R { fill: url(#Molding-R); } /* right */
      .Molding-B { fill: url(#Molding-B); } /* bottom */
    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 171.58" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 129.18" 
      xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-2" data-name="inox" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 100.91" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 58.5" 
      xlink:href="#handle-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox" 
      :x1="doorLeftWidth1 + 70.8" 
      :y1="doorTopHeight1 + 242.27" 
      :x2="doorLeftWidth1 + 70.8" 
      :y2="doorTopHeight1 + 199.86" 
      xlink:href="#handle-gradient"/>

    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>

    <!-- Molding gradient BEGIN -->
    <linearGradient id="Molding-L" data-name="Molding" x1="0" y1="0" x2="100%" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-T" data-name="Molding" x1="0" y1="0" x2="0" y2="100%">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-R" data-name="Molding" x1="100%" y1="0" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <linearGradient id="Molding-B" data-name="Molding" x1="0" y1="100%" x2="0" y2="0">
      <stop offset="0" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.34" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="0.61" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.7" stop-color="#fff" stop-opacity="0.1"/>
      <stop offset="0.77" stop-color="#000" stop-opacity="0.4"/>
      <stop offset="0.86" stop-color="#fff" stop-opacity="0.4"/>
      <stop offset="1" stop-color="#000" stop-opacity="0.5"/>
    </linearGradient>
    <!-- Molding gradient END -->
    <g id="s01">
      <polyline id="inox" v-if="showInox"
                data-name="inox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 129.18} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 129.18} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 171.59} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 171.59} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 129.18}`"/>
      <polyline id="inox-2" v-if="showInox"
                data-name="inox"
                class="cls-5"
                :points="`${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 58.5} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 58.5} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 100.91} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 100.91} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 58.5}`"/>
      <polyline id="inox-3" v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 242.27} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 242.27} ${doorLeftWidth1 + 92.01} ${doorTopHeight1 + 199.87} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 199.87} ${doorLeftWidth1 + 49.6} ${doorTopHeight1 + 242.27}`"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-7"
                :points="`${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 63.45} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 63.45} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 95.96} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 95.96} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 63.45}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2"
                data-name="glass"
                class="cls-8"
                :points="`${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 134.13} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 134.13} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 166.64} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 166.64} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 134.13}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-3"
                data-name="glass"
                class="cls-9"
                :points="`${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 204.81} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 204.81} ${doorLeftWidth1 + 54.55} ${doorTopHeight1 + 237.32} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 237.32} ${doorLeftWidth1 + 87.06} ${doorTopHeight1 + 204.81}`"/>
      <!-- Molding BEGIN -->
      <g v-if="showMolding">
        <g id="Molding-1" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1t + 62.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 62.31}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1t + 57.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1t + 57.93}
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1t + 62.31}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 95.55}
              ${doorLeftWidth1 + 86.39} ${doorTopHeight1t + 95.55}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1t + 99.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1t + 99.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 95.55}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 62.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 95.55}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1t + 99.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1t + 57.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 62.31}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1t + 95.55}
              ${doorLeftWidth1 + 86.22} ${doorTopHeight1t + 62.31}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1t + 57.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1t + 99.93}
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1t + 95.55}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1t + 62.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 62.31}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1t + 57.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1t + 57.93}
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1t + 62.31}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 95.55}
              ${doorLeftWidth1 + 86.39} ${doorTopHeight1t + 95.55}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1t + 99.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1t + 99.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 95.55}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 62.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 95.55}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1t + 99.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1t + 57.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1t + 62.31}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1t + 95.55}
              ${doorLeftWidth1 + 86.22} ${doorTopHeight1t + 62.31}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1t + 57.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1t + 99.93}
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1t + 95.55}
            `"/>
        </g>
        <g id="Molding-2" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1m + 132.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 132.31}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1m + 127.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1m + 127.93}
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1m + 132.31}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 165.55}
              ${doorLeftWidth1 + 86.39} ${doorTopHeight1m + 165.55}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1m + 169.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1m + 169.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 165.55}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 132.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 165.55}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1m + 169.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1m + 127.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 132.31}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1m + 165.55}
              ${doorLeftWidth1 + 86.22} ${doorTopHeight1m + 132.31}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1m + 127.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1m + 169.93}
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1m + 165.55}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1m + 132.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 132.31}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1m + 127.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1m + 127.93}
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1m + 132.31}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 165.55}
              ${doorLeftWidth1 + 86.39} ${doorTopHeight1m + 165.55}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1m + 169.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1m + 169.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 165.55}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 132.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 165.55}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1m + 169.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1m + 127.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1m + 132.31}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1m + 165.55}
              ${doorLeftWidth1 + 86.22} ${doorTopHeight1m + 132.31}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1m + 127.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1m + 169.93}
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1m + 165.55}
            `"/>
        </g>
        <g id="Molding-3" data-name="Molding">
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1b + 202.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 202.31}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1b + 197.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1b + 197.93}
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1b + 202.31}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 235.55}
              ${doorLeftWidth1 + 86.39} ${doorTopHeight1b + 235.55}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1b + 239.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1b + 239.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 235.55}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 202.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 235.55}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1b + 239.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1b + 197.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 202.31}
            `"/>
          <polygon
            class="Solid"
            :points="`
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1b + 235.55}
              ${doorLeftWidth1 + 86.22} ${doorTopHeight1b + 202.31}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1b + 197.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1b + 239.93}
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1b + 235.55}
            `"/>
          <polygon
            class="Molding-T"
            :points="`
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1b + 202.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 202.31}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1b + 197.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1b + 197.93}
              ${doorLeftWidth1 + 86.28} ${doorTopHeight1b + 202.31}
            `"/>
          <polygon
            class="Molding-B"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 235.55}
              ${doorLeftWidth1 + 86.39} ${doorTopHeight1b + 235.55}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1b + 239.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1b + 239.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 235.55}
            `"/>
          <polygon
            class="Molding-L"
            :points="`
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 202.31}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 235.55}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1b + 239.93}
              ${doorLeftWidth1 + 49.12} ${doorTopHeight1b + 197.93}
              ${doorLeftWidth1 + 53.91} ${doorTopHeight1b + 202.31}
            `"/>
          <polygon
            class="Molding-R"
            :points="`
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1b + 235.55}
              ${doorLeftWidth1 + 86.22} ${doorTopHeight1b + 202.31}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1b + 197.93}
              ${doorLeftWidth1 + 91.18} ${doorTopHeight1b + 239.93}
              ${doorLeftWidth1 + 86.33} ${doorTopHeight1b + 235.55}
            `"/>
        </g>
      </g>
      <!-- Molding END -->
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showMolding',
    'doorSolidColor',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
    doorTopHeight1t() {
      return this.doorTopHeight + this.doorHeight/2 - 152
    },
    doorTopHeight1m() {
      return this.doorTopHeight + this.doorHeight/2 - 151.5
    },
    doorTopHeight1b() {
      return this.doorTopHeight + this.doorHeight/2 - 151
    },
  }
}
</script>
